<template>
  <div>
    <div style="margin: 10px 0">
      <el-select clearable v-model="selectType" placeholder="请选择工单类型">
        <el-option label="PM定期任务" value="PM定期任务"></el-option>
        <el-option label="周末保养任务" value="周末保养任务"></el-option>
        <el-option label="技改任务" value="技改任务"></el-option>
      </el-select>
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" v-if="user.role!=='ROLE_READ'" >新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference" v-if="user.role!=='ROLE_READ'" >批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <el-upload action="http://39.106.92.106:9090/user/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5" v-if="user.role!=='ROLE_READ'" >导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column prop="type" label="工单类型"></el-table-column>
      <el-table-column label="派发人">
        <template v-slot="scope">
          <span>{{ users.find(v => v.id === scope.row.userId) ? users.find(v => v.id === scope.row.userId).nickname : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="scheduleDay" label="周期(星期)"></el-table-column>
      <el-table-column prop="scheduleType" label="定时类型"></el-table-column>
      <el-table-column prop="actTime" label="首次派发时间"></el-table-column>
      <el-table-column label="部门">
        <template v-slot="scope">
          <span>{{ partments.find(v => v.id === scope.row.partId) ? partments.find(v => v.id === scope.row.partId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="车间">
        <template v-slot="scope">
          <span>{{ lines.find(v => v.id === scope.row.lineId) ? lines.find(v => v.id === scope.row.lineId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备">
        <template v-slot="scope">
          <span>{{ equipments.find(v => v.id === scope.row.equipmentId) ? equipments.find(v => v.id === scope.row.equipmentId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column label="启用" width="50">
        <template slot-scope="scope">
<!--          <el-switch v-model="scope.row.state" active-color="#13ce66" inactive-color="#ccc"
                     @change="changeEnable(scope.row)"></el-switch>-->
          <el-checkbox v-model="scope.row.state" @change="changeEnable(scope.row)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="300" align="center">
        <template slot-scope="scope">
              <el-button type="primary" plain @click="viewReport(scope.row.id)">查询关联单 <i class="el-icon-document"></i></el-button>
              <el-button type="success" @click="handleEdit(scope.row)" v-if="user.role!=='ROLE_READ'" >编辑 <i class="el-icon-edit"></i></el-button>
              <el-popconfirm
                  class="ml-5"
                  confirm-button-text='确定'
                  cancel-button-text='我再想想'
                  icon="el-icon-info"
                  icon-color="red"
                  title="您确定删除吗？"
                  @confirm="del(scope.row.id)"
              >
                <el-button type="danger" slot="reference" v-if="user.role!=='ROLE_READ'" >删除 <i class="el-icon-remove-outline"></i></el-button>
              </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="工单类型">
          <el-select clearable v-model="form.type" placeholder="请选择工单类型" style="width: 100%">
            <el-option label="PM定期任务" value="PM定期任务"></el-option>
            <el-option label="周末保养任务" value="周末保养任务"></el-option>
            <el-option label="技改任务" value="技改任务"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定时类型">
          <el-select clearable v-model="form.scheduleType" placeholder="请选择定时类型" style="width: 100%">
            <el-option label="一次性" value="一次性"></el-option>
            <el-option label="周期性" value="周期性"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="派发时间">
          <el-date-picker v-model="form.actTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.scheduleType === '周期性'" label="周期(星期)">
          <el-input-number
              v-model="form.scheduleDay"
              :min="0"
              placeholder="请输入周期数"
          />
        </el-form-item>
        <el-form-item label="部门">
          <el-select clearable v-model="form.partId" placeholder="请选择部门" style="width: 100%">
            <el-option v-for="item in partments" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车间">
          <el-select clearable v-model="form.lineId" placeholder="请选择车间" style="width: 100%">
            <el-option v-for="item in filteredLines" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备">
          <el-select v-model="form.equipmentId" clearable filterable placeholder="请选择设备">
            <el-option v-for="item in filteredEquipments" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.description" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="关联报修单" :visible.sync="dialogFormVisible2" width="60%" :close-on-click-modal="false">
      <el-table :data="reports" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
        <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
        <el-table-column label="设备">
          <template v-slot="scope">
            <span>{{ equipments.find(v => v.id === scope.row.equipmentId) ? equipments.find(v => v.id === scope.row.equipmentId).name : ''  }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="报修类型"></el-table-column>
        <el-table-column prop="state" label="状态"></el-table-column>
        <el-table-column prop="recordTime" label="报修时间"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Schedule",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      username: "",
      workTime: "",
      selectType: "",
      startDate : "",
      endDate : "",
      form: {},
      reports: [],
      dialogFormVisible: false,
      dialogFormVisible2: false,
      partments: [],
      users: [],
      lines: [],
      equipments: [],
      baseURLdata: request.defaults.baseURL,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  computed: {
    filteredLines() {
      // 根据选择的部门过滤车间
      return this.lines.filter(line => line.partId === this.form.partId);
    },
    filteredEquipments() {
      return this.equipments.filter(item => item.lineId === this.form.lineId);
    }
  },
  methods: {
    load() {
      this.request.get("/schedule/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          selectType: this.selectType,
          startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
          endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : ''
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/partment").then(res => {
        this.partments = res.data
      })
      this.request.get("/line").then(res => {
        this.lines = res.data
      })
      this.request.get("/equipment").then(res => {
        this.equipments = res.data
      })
      this.request.get("/user").then(res => {
        this.users = res.data
      })
    },
    save() {
      let equipmentName = this.equipments.find(v => v.id === this.form.equipmentId).name;
      const formData = {
        userId: this.user.id,
        type: this.form.type,
        partId: this.form.partId,
        lineId: this.form.lineId,
        equipmentId: this.form.equipmentId,
        equipmentName: equipmentName,
        scheduleType: this.form.scheduleType,
        scheduleDay: this.form.scheduleDay,
        actTime: this.form.actTime,
        description: this.form.description,
      }
      this.request.post("/schedule", formData).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    del(id) {
      this.request.delete("/schedule/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/schedule/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    changeEnable(row) {
      this.request.post("/schedule", row).then(res => {
        if (res.code === '200') {
          this.$message.success("操作成功")
        }
      })
    },
    reset() {
      this.selectType = ""
      this.startDate = ""
      this.endDate = ""
      this.load()
    },
    viewReport(scheduleId){
      this.request.get("/repairReport/findReportBySchedule/"+scheduleId).then(res => {
        this.reports = res.data;
      });
      this.dialogFormVisible2 = true;
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    exp() {
      //window.open(this.baseURLdata+"/schedule/export")
      // 获取筛选条件的值
      const params = new URLSearchParams();
      if (this.selectType) {
        params.append('selectType', this.selectType);
      }
      if (this.startDate) {
        params.append('startDate', this.startDate ? `${this.startDate.getFullYear()}-${(this.startDate.getMonth() + 1).toString().padStart(2, '0')}-${this.startDate.getDate().toString().padStart(2, '0')}` : '');
      }
      if (this.endDate) {
        params.append('endDate', this.endDate ? `${this.endDate.getFullYear()}-${(this.endDate.getMonth() + 1).toString().padStart(2, '0')}-${this.endDate.getDate().toString().padStart(2, '0')}` : '');
      }

      // 打开新窗口进行导出
      window.open(this.baseURLdata + "/schedule/export?" + params.toString());
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee !important;
}
</style>
